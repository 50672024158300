import React from 'react'
import Layout from '../../components/layout'
import { StaticQuery, graphql } from 'gatsby'

import Nav from '../../components/cn/nav'

import Divider from '../../images/divider.png'

const OnlineCoursesPage = () => (
  <StaticQuery
    query={graphql`
      query CNCoursesQuery {
        wordpressPage(wordpress_id: { eq: 176 }) {
          acf {
            circle_image {
              source_url
            }
            chinese
            cn_courses {
              course_name
              course_description
              course_label
              course_link
              course_image {
                source_url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Nav />
        <div className="app-main body-main">
          <div
            className="top-image"
            style={{
              backgroundImage: `url(${Divider})`,
            }}
          >
            <img
              alt=""
              className="circle"
              src={data.wordpressPage.acf.circle_image.source_url}
            />
          </div>
          <h1>医疗服务行业在线课程</h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.chinese }}
          />
          <h2>阿姆斯壮大学推荐课程</h2>
          {data.wordpressPage.acf.cn_courses.map(repeater => (
            <div className="course" key={repeater.course_name}>
              <img alt="divider" src={Divider} />
              <img
                alt="course"
                className="circle"
                src={repeater.course_image.source_url}
              />
              <h2>{repeater.course_name}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: repeater.course_description,
                }}
              />
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href={repeater.course_link}
              >
                {repeater.course_label}
              </a>
            </div>
          ))}
        </div>
      </Layout>
    )}
  />
)

export default OnlineCoursesPage
